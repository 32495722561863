/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:af2da4c3-4deb-492b-ad5e-788294fe7089",
    "aws_cognito_region": "ap-northeast-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_mobile_analytics_app_id": "a49246df0808457a948a35ad752dea69",
    "aws_mobile_analytics_app_region": "ap-northeast-1"
};


export default awsmobile;
